<template>
  <default-layout>
    <div class="container">
      <static-page />
    </div>
  </default-layout>
</template>

<script>
const StaticPage = () => import('@/components/static-page/term-of-service');
import DefaultLayout from '@/layouts/default';

export default {
  async fetch({ store, route }) {
    const baseUrl = store.state.global.baseUrl;
    const title = 'Syarat dan Ketentuan Rentfix.com - Platform Sewa Properti di Indonesia';
    const description =
      'Syarat dan Ketentuan Rentfix.com, situs yang membantu Anda melakukan proses sewa properti mulai dari rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di seluruh Indonesia.';
    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  components: {
    StaticPage,
    DefaultLayout,
  },
};
</script>
